import { useEffect } from 'react';

import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { useToast } from '../hooks';

const HomePage = () => {
  const { isDomainReady } = useDomain();
  const { getLoginUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError } = useToast();

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    push(getLoginUrl()).catch((error) =>
      handleToastError({
        error,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return null;
};

// eslint-disable-next-line import/no-default-export
export default HomePage;
